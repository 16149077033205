import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';

import PropTypes from 'prop-types';
import { getNominationRoundYear } from '../../utils/getNominationRoundYear';

import { addCmseCommitteeToSectionalCommitteeArray } from '../../utils/addCmseCommitteeToSectionalCommitteeArray';
import { useStaticCollections } from '../../context/StaticCollections';
import { useAdminCollections } from '../../context/AdminCollections';
import { useLoggedInUserContextProvider } from '../../context/LoggedInUserContext';

const SectionalCommittees = ({
  TableComponent,
  handleSelectClick,
  statusTypes,
}) => {
  const { userDetails } = useLoggedInUserContextProvider();
  const { sectionalCommitteesArray } = useStaticCollections();
  const { nominationArray, chairCommitteeArray, candidates, proposerSeconder } =
    useAdminCollections();

  const [nominations, setNominations] = useState([]);
  const [sectionalCommitteeId, setSectionalCommitteeId] = useState(null);
  const [sectionalCommitteeArray, setSectionalCommitteeArray] = useState([]);

  useEffect(() => {
    const currentYear = getNominationRoundYear();

    const shortlistedAndElectedNominations = nominationArray
      .filter((item) => {
        const nominationReceivedEmailDate = proposerSeconder.find(
          (proposer) => proposer.attributes.nominationId === item.id,
        );

        if (
          nominationReceivedEmailDate?.attributes?.nominationReceivedEmailDate
        ) {
          const receivedDate =
            nominationReceivedEmailDate.attributes.nominationReceivedEmailDate.slice(
              0,
              10,
            );
          const receivedAt = new Date(receivedDate);
          const nominationReceivedRoundYear =
            getNominationRoundYear(receivedAt);

          return (
            statusTypes.includes(item.attributes.nominationStatusTypeId) &&
            nominationReceivedRoundYear === currentYear
          );
        }

        return null;
      })
      .map((nomination) => {
        nomination.attributes.candidate =
          candidates[nomination.attributes.candidateId] || null;
        return nomination;
      });

    setNominations(shortlistedAndElectedNominations);
  }, [nominationArray, statusTypes, candidates, proposerSeconder]);

  useEffect(() => {
    try {
      if (
        userDetails &&
        (userDetails?.roles?.includes('committee-chair') ||
          userDetails?.roles?.includes('committee-member')) &&
        chairCommitteeArray.length > 0
      ) {
        if (userDetails?.roles?.includes('committee-chair')) {
          const userChairCommittee = chairCommitteeArray.find(
            (item) =>
              item.attributes?.committeeChair?.data?.id === userDetails.id,
          );
          if (userChairCommittee && userChairCommittee.id) {
            setSectionalCommitteeId(userChairCommittee.id);
          }
        }
        if (userDetails?.roles?.includes('committee-member')) {
          const userChairCommittee = chairCommitteeArray.find((committee) =>
            committee.attributes.committeeMembers.data.some(
              (member) => member.id === userDetails.id,
            ),
          );
          if (userChairCommittee && userChairCommittee.id) {
            setSectionalCommitteeId(userChairCommittee.id);
          }
        }
      }
    } catch (error) {
      console.error('Error in NominationShortlist:', error);
    }
  }, [chairCommitteeArray, userDetails]);

  useEffect(() => {
    let result = [];
    if (sectionalCommitteeId === 14) {
      result = [
        {
          id: 14,
          attributes: {
            name: 'CMSE: Corresponding Member and Special Election Committee',
          },
        },
      ];
    } else {
      result =
        (userDetails && userDetails?.roles?.includes('super-admin')) ||
        userDetails?.roles?.includes('admin') ||
        sectionalCommitteeId === null
          ? sectionalCommitteesArray
          : [
              sectionalCommitteesArray.find(
                (item) => item.id === sectionalCommitteeId,
              ),
            ];

      if (
        (userDetails && userDetails?.roles?.includes('super-admin')) ||
        userDetails?.roles?.includes('admin')
      ) {
        result = addCmseCommitteeToSectionalCommitteeArray(result);
      }
    }

    setSectionalCommitteeArray(result); // Store the computed result in state
  }, [
    sectionalCommitteeId,
    userDetails,
    sectionalCommitteesArray,
    chairCommitteeArray,
  ]);
  return (
    <>
      {sectionalCommitteeArray.map((committee, indexCategory) => {
        const nominationsInSectionalCommittee = nominations
          .filter(
            (nomination) =>
              nomination.attributes.sectionalCommitteeId === committee.id ||
              (nomination.attributes.electionTypeId !== 2 &&
                !nomination.attributes.sectionalCommitteeId &&
                committee.id === 14),
          )
          .sort((a, b) =>
            a.attributes.candidate.localeCompare(b.attributes.candidate),
          );
        const overlapNominations = nominations.filter(
          (nomination) =>
            (nomination.attributes.overlapSectionalCommitteeId ===
              committee.id &&
              nomination.attributes.overlapCommittee) ||
            (nomination.attributes.electionTypeId !== 2 &&
              !nomination.attributes.sectionalCommitteeId &&
              committee.id === 14),
        );

        if (nominationsInSectionalCommittee.length > 0) {
          return (
            <Row
              key={committee.id}
              className={indexCategory !== 0 ? 'mt-xl-5' : 'mb-xl-5'}
            >
              <Col>
                <div className="row page-titles">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                      <h4 className="text-center">
                        {committee.attributes.name}
                      </h4>
                    </li>
                  </ol>
                </div>

                <TableComponent
                  nominationsInSectionalCommittee={
                    nominationsInSectionalCommittee
                  }
                  overlapNominations={overlapNominations}
                  handleSelectClick={handleSelectClick}
                />
              </Col>
            </Row>
          );
        }

        return null;
      })}
    </>
  );
};

SectionalCommittees.propTypes = {
  TableComponent: PropTypes.elementType,
  statusTypes: PropTypes.array,
  handleSelectClick: PropTypes.func,
};

export default SectionalCommittees;
