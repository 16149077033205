import React, { useState, useContext } from 'react';
import { Card, Button, Table } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import PageTitle from '../layouts/PageTitle';
import ModalConfirmation from './ModalConfirmation';

import { handleConfirmSelect } from '../../utils/handleConfirmSelect';
import { CommonCollections } from '../../App';

import { strapiUrl } from '../../utils/constants';
import SectionalCommittees from './SectionalCommittees';

import DownloadFile from './Forms/Elements/DownloadFile';
import { useStaticCollections } from '../../context/StaticCollections';
import { useAdminCollections } from '../../context/AdminCollections';
import { getReportUploadedYear } from '../../utils/getReportUploadedYear';
import { useLoggedInUserContextProvider } from '../../context/LoggedInUserContext';

const TableNominationShortlist = ({
  nominationsInSectionalCommittee,
  overlapNominations,
  handleSelectClick,
}) => {
  const {
    activeFellows,
    declineReasons,
    nonAasReferees,
    refereeStatuses,
    sectionalCommittees,
  } = useStaticCollections();
  const { candidates } = useAdminCollections();
  const { setNominationId, refereeReports } = useContext(CommonCollections);
  const { userDetails } = useLoggedInUserContextProvider();
  return (
    <>
      {nominationsInSectionalCommittee.map((nomination) => {
        const filteredReports = refereeReports.filter(
          (report) =>
            report.attributes.nominationId === nomination.id &&
            !report.attributes.refereeArchived,
        );

        return (
          <Card
            key={nomination.id}
            className="card-two mb-xl-5 table-responsive"
          >
            <Card.Body>
              <Card.Title className="mb-xl-4">
                <div className="row align-items-center">
                  <div className="col">
                    {candidates[nomination.attributes.candidateId]}{' '}
                    {nomination.attributes.sectionalCommitteeId !== null &&
                      nomination.attributes.overlapCommittee &&
                      nomination.attributes.overlapSectionalCommitteeId !==
                        null && (
                        <span className="badge badge-primary m-1">
                          <i>
                            {
                              sectionalCommittees[
                                nomination.attributes.sectionalCommitteeId
                              ]
                            }{' '}
                            Overlaps with{' '}
                            {
                              sectionalCommittees[
                                nomination.attributes
                                  .overlapSectionalCommitteeId
                              ]
                            }
                          </i>
                        </span>
                      )}
                    <Link
                      to="/form-view"
                      onClick={() => {
                        setNominationId(nomination.id);
                      }}
                      onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                          setNominationId(nomination.id);
                        }
                      }}
                    >
                      <span title="View">
                        <i className="bi bi-eye view-button" />
                      </span>
                    </Link>
                  </div>
                </div>
              </Card.Title>
              <Table bordered hover striped className="dataTable">
                <thead className="thead-dark">
                  <tr>
                    <th>
                      <b>Name</b>
                    </th>
                    <th>
                      <b>Status</b>
                    </th>
                    <th>
                      <b>Type</b>
                    </th>
                    <th>
                      <b>Round</b>
                    </th>
                    <th aria-label="Actions">
                      <b />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredReports.map((referee, index) => {
                    const reportYear = getReportUploadedYear(
                      referee.attributes.report,
                      referee.attributes.refereeReportUploadedRound,
                    );
                    const candidateName =
                      candidates[nomination.attributes.candidateId];
                    const candidateWithoutSpaces = candidateName
                      ? candidateName.replace(/\s/g, '')
                      : '';
                    const refereeName = referee.attributes.refereeFellowId
                      ? activeFellows[referee.attributes.refereeFellowId]
                      : nonAasReferees[referee.attributes.nonAasRefereeId];

                    let reason = '';
                    const {
                      refereeStatusId,
                      declineReasonId,
                      declineReasonOther,
                      extensionReason,
                      extensionReasonApprovalNote,
                    } = referee.attributes;

                    if (refereeStatusId === 3) {
                      reason =
                        declineReasonId === 5
                          ? declineReasonOther
                          : declineReasons[declineReasonId];
                    } else if (refereeStatusId === 5) {
                      reason = extensionReason;
                    } else if (refereeStatusId === 6) {
                      reason = extensionReasonApprovalNote;
                    }

                    return (
                      <tr key={index}>
                        <td>{refereeName}</td>
                        <td>
                          <span>
                            {reason ? (
                              <>
                                <span>
                                  <b>
                                    {
                                      refereeStatuses[
                                        referee.attributes.refereeStatusId
                                      ]
                                    }
                                    {' : '}
                                  </b>
                                </span>
                                {refereeStatusId === 6 ? (
                                  <>
                                    <br />
                                    <span>Reason: {extensionReason}</span>
                                    <br />
                                    <span>
                                      Approval Note:{' '}
                                      {extensionReasonApprovalNote}
                                    </span>
                                  </>
                                ) : (
                                  <span>{reason}</span>
                                )}
                              </>
                            ) : (
                              <span>
                                {
                                  refereeStatuses[
                                    referee.attributes.refereeStatusId
                                  ]
                                }
                              </span>
                            )}
                          </span>
                        </td>
                        <td>
                          {referee.attributes.independentAssessor === true
                            ? 'Independent Assessor'
                            : 'Referee'}
                        </td>
                        <td>{reportYear}</td>
                        <td className="text-center">
                          {referee.attributes.report.data !== null && (
                            <DownloadFile
                              text={`Report Uploaded Year : ${reportYear}`}
                              url={`${strapiUrl}${referee.attributes.report.data.attributes.url}`}
                              name={`${candidateWithoutSpaces}${refereeName}`}
                              buttonClassName="action-button-two"
                              iconClassName="action-button action-button-alt"
                              showFileName={false}
                              showText={false}
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="row justify-content-end mt-xl-4">
                {userDetails &&
                  (userDetails?.roles?.includes('committee-chair') ||
                    userDetails?.roles?.includes('admin') ||
                    userDetails?.roles?.includes('super-admin')) && (
                    <div className="col-auto shortlist-button">
                      {nomination.attributes.nominationStatusTypeId === 3 ? (
                        <Button variant="success" disabled>
                          Shortlisted
                        </Button>
                      ) : (
                        <Button
                          variant="success"
                          onClick={() =>
                            handleSelectClick(
                              nomination.id,
                              candidates[nomination.attributes.candidateId],
                            )
                          }
                        >
                          Shortlist
                        </Button>
                      )}
                    </div>
                  )}
              </div>
            </Card.Body>
          </Card>
        );
      })}
      {overlapNominations.map((nomination) => {
        const filteredReports = refereeReports.filter(
          (report) =>
            report.attributes.nominationId === nomination.id &&
            !report.attributes.refereeArchived,
        );
        return (
          <Card
            key={nomination.id}
            className="card-two mb-xl-5 table-responsive"
          >
            <Card.Body>
              <Card.Title className="mb-xl-4">
                <div className="row align-items-center">
                  <div className="col">
                    {candidates[nomination.attributes.candidateId]}{' '}
                    {nomination.attributes.sectionalCommitteeId !== null &&
                      nomination.attributes.overlapCommittee &&
                      nomination.attributes.overlapSectionalCommitteeId !==
                        null && (
                        <span className="badge badge-primary m-1">
                          <i>
                            {
                              sectionalCommittees[
                                nomination.attributes.sectionalCommitteeId
                              ]
                            }{' '}
                            Overlaps with{' '}
                            {
                              sectionalCommittees[
                                nomination.attributes
                                  .overlapSectionalCommitteeId
                              ]
                            }
                          </i>
                        </span>
                      )}
                    <Link
                      to="/form-view"
                      onClick={() => {
                        setNominationId(nomination.id);
                      }}
                      onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                          setNominationId(nomination.id);
                        }
                      }}
                    >
                      <span title="View">
                        <i className="bi bi-eye view-button" />
                      </span>
                    </Link>
                  </div>
                </div>
              </Card.Title>
              <Table bordered hover striped className="dataTable">
                <thead className="thead-dark">
                  <tr>
                    <th>
                      <b>Name</b>
                    </th>
                    <th>
                      <b>Status</b>
                    </th>
                    <th>
                      <b>Type</b>
                    </th>
                    <th>
                      <b>Round</b>
                    </th>
                    <th aria-label="Actions">
                      <b />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredReports.map((referee, index) => {
                    const reportYear = getReportUploadedYear(
                      referee.attributes.report,
                      referee.attributes.refereeReportUploadedRound,
                    );
                    const candidateName =
                      candidates[nomination.attributes.candidateId];
                    const candidateWithoutSpaces = candidateName
                      ? candidateName.replace(/\s/g, '')
                      : '';
                    const refereeName = referee.attributes.refereeFellowId
                      ? activeFellows[referee.attributes.refereeFellowId]
                      : nonAasReferees[referee.attributes.nonAasRefereeId];

                    let reason = '';
                    const {
                      refereeStatusId,
                      declineReasonId,
                      declineReasonOther,
                      extensionReason,
                      extensionReasonApprovalNote,
                    } = referee.attributes;

                    if (refereeStatusId === 3) {
                      reason =
                        declineReasonId === 5
                          ? declineReasonOther
                          : declineReasons[declineReasonId];
                    } else if (refereeStatusId === 5) {
                      reason = extensionReason;
                    } else if (refereeStatusId === 6) {
                      reason = extensionReasonApprovalNote;
                    }

                    return (
                      <tr key={index}>
                        <td>{refereeName}</td>
                        <td>
                          <span>
                            {reason ? (
                              <>
                                <span>
                                  <b>
                                    {
                                      refereeStatuses[
                                        referee.attributes.refereeStatusId
                                      ]
                                    }
                                    {' : '}
                                  </b>
                                </span>
                                {refereeStatusId === 6 ? (
                                  <>
                                    <br />
                                    <span>Reason: {extensionReason}</span>
                                    <br />
                                    <span>
                                      Approval Note:{' '}
                                      {extensionReasonApprovalNote}
                                    </span>
                                  </>
                                ) : (
                                  <span>{reason}</span>
                                )}
                              </>
                            ) : (
                              <span>
                                {
                                  refereeStatuses[
                                    referee.attributes.refereeStatusId
                                  ]
                                }
                              </span>
                            )}
                          </span>
                        </td>
                        <td>
                          {referee.attributes.independentAssessor === true
                            ? 'Independent Assessor'
                            : 'Referee'}
                        </td>
                        <td>{reportYear}</td>
                        <td className="text-center">
                          {referee.attributes.report.data !== null && (
                            <DownloadFile
                              text={`Report Uploaded Year : ${reportYear}`}
                              url={`${strapiUrl}${referee.attributes.report.data.attributes.url}`}
                              name={`${candidateWithoutSpaces}${refereeName}`}
                              buttonClassName="action-button-two"
                              iconClassName="action-button action-button-alt"
                              showFileName={false}
                              showText={false}
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        );
      })}
    </>
  );
};

const NominationShortlist = () => {
  const { refreshNominations, setRefreshNominations } = useAdminCollections();

  const [showModal, setShowModal] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);

  const [selectedNominationId, setSelectedNominationId] = useState(null);

  const handleSelectClick = (nominationId, candidate) => {
    setSelectedCandidate(candidate);
    setSelectedNominationId(nominationId);
    setShowModal(true);
  };

  const handleCancelSelect = () => {
    setShowModal(false);
  };

  return (
    <>
      <PageTitle motherMenu="Nominations" activeMenu="Shortlist candidate" />
      <SectionalCommittees
        TableComponent={TableNominationShortlist}
        statusTypes={[2, 3]}
        handleSelectClick={handleSelectClick}
      />

      <ModalConfirmation
        showModal={showModal}
        handleCancelSelect={handleCancelSelect}
        bodyText={`Are you sure you want to **shortlist** ${selectedCandidate}?`}
        handleConfirmSelect={handleConfirmSelect}
        rowId={`${selectedNominationId}`}
        apiIdentifier="nomination-shortlist"
        refreshCollections={refreshNominations}
        setRefreshCollections={setRefreshNominations}
        setShowModal={setShowModal}
      />
    </>
  );
};

TableNominationShortlist.propTypes = {
  nominationsInSectionalCommittee: PropTypes.array,
  overlapNominations: PropTypes.array,
  handleSelectClick: PropTypes.func,
};

export default NominationShortlist;
