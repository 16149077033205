import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { axiosGet } from '../utils/axiosGet';
import { getCollectionRowNames } from '../utils/getCollectionRowNames';
import { filteringTableFormatDraftNomination } from '../utils/filteringTableFormatDraftNomination';
import { useLoggedInUserContextProvider } from './LoggedInUserContext';

const AdminCollections = createContext();

export const AdminCollectionsProvider = ({ children }) => {
  const [candidates, setCandidates] = useState([]);
  const [chairCommitteeArray, setChairCommitteeArray] = useState([]);
  const [candidateStateArray, setCandidateStateArray] = useState([]);
  const [draftNominationArray, setDraftNominationArray] = useState([]);
  const [nominationArray, setNominationArray] = useState([]);
  const [proposerSeconder, setProposerSeconder] = useState([]);
  const [nominationState, setNominationState] = useState([]);
  const [refereeReports, setRefereeReports] = useState([]);
  const [refreshAdminCollections, setRefreshAdminCollections] = useState(false);
  const [refreshNominations, setRefreshNominations] = useState(false);

  const { userDetails } = useLoggedInUserContextProvider();

  useEffect(() => {
    if (userDetails) {
      axiosGet('candidates').then((response) => {
        setCandidates(getCollectionRowNames(response.data.data));
      });
      axiosGet('chair-committees?populate=*').then((response) => {
        setChairCommitteeArray(response.data.data);
      });
      axiosGet('candidate-states').then((response) => {
        setCandidateStateArray(response.data.data);
      });
      axiosGet('nominations').then((response) => {
        setNominationArray(response.data.data);
      });
      axiosGet('nomination-drafts').then((response) => {
        const formattedDraftNomination = filteringTableFormatDraftNomination(
          response.data.data,
        );
        setDraftNominationArray(formattedDraftNomination);
      });
      axiosGet('proposer-seconders').then((response) => {
        setProposerSeconder(response.data.data);
      });
      axiosGet('nomination-states').then((response) => {
        setNominationState(response.data.data);
      });
    }
  }, [refreshAdminCollections, userDetails]);

  useEffect(() => {
    if (userDetails) {
      axiosGet('nominations').then((response) => {
        setNominationArray(response.data.data);
      });

      axiosGet('nomination-drafts').then((response) => {
        const formattedDraftNomination = filteringTableFormatDraftNomination(
          response.data.data,
        );
        setDraftNominationArray(formattedDraftNomination);
      });
    }
  }, [refreshNominations, userDetails]);

  return (
    <AdminCollections.Provider
      value={useMemo(
        () => ({
          candidates,
          chairCommitteeArray,
          candidateStateArray,
          draftNominationArray,
          nominationArray,
          proposerSeconder,
          nominationState,
          refereeReports,
          setRefereeReports,
          refreshAdminCollections,
          setRefreshAdminCollections,
          refreshNominations,
          setRefreshNominations,
        }),
        [
          candidates,
          chairCommitteeArray,
          candidateStateArray,
          draftNominationArray,
          nominationArray,
          proposerSeconder,
          nominationState,
          refereeReports,
          setRefereeReports,
          refreshAdminCollections,
          setRefreshAdminCollections,
          refreshNominations,
          setRefreshNominations,
        ],
      )}
    >
      {children}
    </AdminCollections.Provider>
  );
};

AdminCollectionsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAdminCollections = () => useContext(AdminCollections);
